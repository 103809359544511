import React from 'react'
import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import Hero from '../components/Hero'
import CardItem from '../components/CardItem'

class EventRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <form
        name="event-register"
        method="post"
        action="/events/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="event-register" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>

        <div className="field">
          <label className="label" htmlFor={'name'}>
            Name
          </label>
          <div className="control has-icons-left">
            <input
              className="input"
              type={'text'}
              name={'name'}
              placeholder="First and last name"
              onChange={this.handleChange}
              id={'name'}
              required={true}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'company'}>
            Firma
          </label>
          <div className="control has-icons-left">
            <input
              className="input"
              type={'text'}
              name={'company'}
              placeholder="Company name"
              onChange={this.handleChange}
              id={'company'}
              required={true}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-building"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'email'}>
            Email
          </label>
          <div className="control has-icons-left">
            <input
              className="input"
              type={'email'}
              name={'email'}
              placeholder="Work email address"
              onChange={this.handleChange}
              id={'email'}
              required={true}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'role'}>
            Rolle
          </label>
          <div className="control has-icons-left">
            <input
              className="input"
              type={'text'}
              name={'role'}
              placeholder="Role in the company"
              onChange={this.handleChange}
              id={'role'}
              required={false}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-id-badge"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'phone'}>
            Telefonnummer
          </label>
          <div className="control has-icons-left">
            <input
              className="input"
              type={'text'}
              name={'phone'}
              placeholder="Business phone number"
              onChange={this.handleChange}
              id={'phone'}
              required={false}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-mobile"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <button className="button is-primary" type="submit">
            Submit
          </button>
        </div>

        <p className="is-size-7">
          Indem Sie sich registrieren bestätigen Sie, dass sie die{' '}
          <a href="/privacy_statement" target="_blank">
            Datenschutzbestimmungen
          </a>{' '}
          zur Kenntnis genommen haben.
        </p>
      </form>
    )
  }
}

const EventTemplate = ({ meta, content, tags, helmet }) => {
  const { title, date, time, speaker } = meta
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>

            <p>
              <span className="icon">
                <i className="fas fa-calendar" />
              </span>{' '}
              {date} | {time}
            </p>
            <p>
              <span className="icon">
                <i className="fas fa-user" />
              </span>{' '}
              {speaker.name}, {speaker.title}, {speaker.company}
            </p>
            <HTMLContent content={content} />
            <br />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <div className="tags">
                  {tags.map(tag => (
                    <Link
                      key={tag + `tag`}
                      className="tag"
                      to={`/tags/${kebabCase(tag)}/`}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="column is-4">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title has-text-centered has-text-weight-bold">
                  Registrieren Sie sich zur Teilnahme
                </p>
              </header>
              <div className="card-content">
                <EventRegister />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

EventTemplate.propTypes = {
  meta: PropTypes.object,
  content: PropTypes.node.isRequired,
  helmet: PropTypes.object,
}

const Event = ({ data }) => {
  const { event } = data

  return (
    <Layout>
      <Hero
        title="Veranstaltungen bei Colenio"
        subtitle="Registrieren Sie sich für unsere kommenden Veranstaltungen."
        img={data.hero.childImageSharp}
      />
      <EventTemplate
        meta={event.frontmatter}
        content={event.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Event">
            <title>{`${event.frontmatter.title}`}</title>
          </Helmet>
        }
        tags={event.frontmatter.tags}
        title={event.frontmatter.title}
      />
    </Layout>
  )
}

Event.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.object,
  }),
}

export default Event

export const pageQuery = graphql`
  query EventByID($id: String!) {
    event: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        time
        speaker {
          name
          title
          company
        }
      }
    }
    events: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "event" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(height: 256)
              }
            }
            title
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
    hero: file(relativePath: { eq: "event.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
